<template>
  <div style="padding: 0 2px; height: 900px">
    <div style="display: felx; align-items: center; margin-top: 15px">
      <el-form
        :model="queryParams"
        ref="queryForm"
        :inline="true"
        label-width="100px"
      >
        <el-form-item label="选择停车场" prop="visbleValue">
          <el-select
            v-model="queryParams.visbleValue"
            placeholder="请选择停车场"
            @change="changeVisbleValue"
          >
            <el-option
              v-for="item in visbleList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择通道" prop="aisle">
          <el-select
            v-model="queryParams.aisle"
            placeholder="请选择通道"
            @change="changeAisle"
          >
            <el-option
              v-for="item in aisleList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="trueVideo" style="margin: 0 15px">
      <iframe
        :src="page"
        ref="Frame"
        width="100%"
        height="200px"
        frameborder="0"
      ></iframe>
    </div>
    <div v-else style="width: 100%; height: 200px">
      <el-skeleton :rows="6" animated />
    </div>
    <div class="aisleStatus">通道状态: <span class="aisle">在线</span></div>
    <div class="btn">
      <button class="btnOne" @click="tapUp">抬闸</button>
      <button class="btnOne" @click="tapDown">落闸</button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      domain: process.env.VUE_APP_DOMAIN,
      // page: `/page.html?api="{" ${process.env.VUE_APP_DOMAIN}`,
      page: "/page.html?api=" + "http://test.payment.zhongzhiiov.com",
      //应用
      appId: null,
      channelList: null,
      getItemsVisibleList: null,
      count: 1,
      queryParams: {
        //当前选择停车场
        visbleValue: null,
        //选择通道
        aisle: null,
      },
      //visbleList
      visbleList: [],
      aisleList: [],
      //当前停车场通道数据
      list: null,
      trueVideo: true,
    };
  },
  created() {
    //获取停车场列表
    this.channel();
  },
  mounted() {},
  methods: {
    //获取停车场列表
    channel() {
      this.$axios
        .post(
          //本地
          `/parklot/channel/queryParkNameByAppIdAndName`,
          //测试
          // `${this.domain}/parklot/channel/queryParkNameByAppIdAndName`,
          {
            appId: "6209c120c5a41a9a2ee9e383",
          }
        )
        .then((res) => {
          this.getItemsVisibleList = res.data.data;
          let visbleList = [];
          res.data.data.forEach((item) => {
            visbleList.push({
              label: item.parklotName,
              value: item.itemId,
            });
          });
          console.log(visbleList, "visbleList");
          this.count = 0;
          this.visbleList = visbleList;
          this.queryParams.visbleValue = visbleList[0] && visbleList[0].value;
          //获取通道
          this.switchingOff();
        });
    },
    //获取通道列表接口
    switchingOff() {
      sessionStorage.removeItem("detail");
      let { visbleValue } = this.queryParams;
      if (visbleValue == null) {
        return;
      }
      this.$axios
        .post(
          //本地
          `/parklot/channel/queryChannelList`,
          //测试
          // `${this.domain}/parklot/channel/queryChannelList`,
          {
            appId: "6209c120c5a41a9a2ee9e383",
            itemId: visbleValue,
          }
        )
        .then((res) => {
          let aisleList = [];
          if (res.data.data) {
            this.list = res.data.data;
            this.appId = this.list[0].appId;
            res.data.data.forEach((item) => {
              aisleList.push({
                label: item.channelName,
                value: item.channelId,
              });
            });
          }
          this.aisleList = aisleList;
          if (this.count == 0) {
            this.queryParams.aisle = aisleList[0] && aisleList[0].value;
            this.count = 1;
          }
          let list = this.list;
          list &&
            list.forEach((item) => {
              if (item.channelId == this.queryParams.aisle) {
                console.log(item, "nandao1");
                item = JSON.stringify(item);
                sessionStorage.setItem("detail", item);
                this.trueVideo = true;
              }
            });
        });
    },
    //切换停车场
    changeVisbleValue(value) {
      this.count = 0;
      (this.trueVideo = false), this.switchingOff();
    },
    //切换通道
    changeAisle() {
      (this.trueVideo = false), this.switchingOff();
    },
    //抬闸
    tapUp() {
      let { aisle, visbleValue } = this.queryParams;
      let { appId } = this;
      this.$axios
        .post(
          //本地
          `/parklot/channel/liftBrake`,
          //测试
          // `${this.domain}/parklot/channel/liftBrake`,
          {
            appId: appId,
            itemId: visbleValue,
            channelId: aisle,
          }
        )
        .then((res) => {
          console.log("抬闸", res);
        });
    },
    //落闸
    tapDown() {
      let { aisle, visbleValue } = this.queryParams;
      let { appId } = this;
      this.$axios
        .post(
          //本地
          `/parklot/channel/fallBrake`,
          //测试
          // `${this.domain}/parklot/channel/fallBrake`,
          {
            appId: appId,
            itemId: visbleValue,
            channelId: aisle,
          }
        )
        .then((res) => {
          console.log("落闸", res);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
/* 下拉框 */
/* search */
.search-bar {
  width: 100%;
  height: 42px;
  box-sizing: border-box;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-size: 15px;
  padding: 15px;
  box-sizing: border-box;
}

.package {
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  color: rgb(51, 51, 51);
  font-family: PingFang SC;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 25px;
}

.page-section-spacing {
  margin-top: 30px;
}

.leftTime {
  position: absolute;
  top: 0;
}

.scroll-view_H {
  width: 100%;
  white-space: nowrap;
}

.scroll-view-item {
  height: 150px;
}

.scroll-view-item_H {
  display: inline-block;
  width: 245px;
  height: 253px;
  /* 月卡蓝色 */
  background: linear-gradient(
    to right,
    rgba(66, 210, 252, 255) 0%,
    rgba(38, 146, 251, 255) 100%
  );
  /* 月卡阴影 */
  box-shadow: 0px 2px 10px rgba(38, 146, 251, 0.5);
  border-radius: 5px;
  margin: 0 5px;
  position: relative;
  padding: 15px;
  box-sizing: border-box;
}

.road {
  width: 100px;
  height: 50px;
  color: rgb(51, 51, 51);
  font-family: PingFang SC;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  margin-right: 15px;
}

.search-bar .condition {
  width: 240px;
  height: 43px;
  border-radius: 20px;
  background: #817f7f;
  display: flex;
  border: 1px solid rgb(83, 82, 82);
  /* 灰色背景 */
  background: rgb(248, 248, 248);
  /* 描边 */
  border: 1px solid rgb(229, 229, 229);
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 10px;
  position: relative;
}

.search-bar .condition .select-condition {
  color: #575555;
  /* font-size: 26rpx; */
  font-size: 15px;
}

.search-bar .condition .trigger {
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-top: 7px solid #c2c2c2;
  position: relative;
  top: 8rpx;
  transform: rotate(0deg);
  transform-origin: center 3.5px;
  transition: transform 0.4s;
}

.search-bar .condition .trigger.reverse {
  transform: rotate(180deg);
  transform-origin: center 3.5px;
  transition: transform 0.4s;
}

.search-bar .condition .option-list {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 150px;
  left: 0;
  top: 40px;
  box-sizing: border-box;
  /* 纯白 */
  background: rgb(248, 248, 248);
  /* 卡片黑色阴影 */
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 0px 1px;
  margin-top: 2px;
  border-radius: 3px;
  overflow: auto !important;
}

.option-list .list-item {
  color: #3d3d3d;
  font-size: 13px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-bar .condition .select-condition {
  color: #575555;
  /* font-size: 26rpx; */
  font-size: 15px;
}

.search-bar .condition .trigger {
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-top: 7.5px solid #c2c2c2;
  position: relative;
  top: 8rpx;
  transform: rotate(0deg);
  transform-origin: center 3.5px;
  transition: transform 0.4s;
}

.search-bar .condition .trigger.reverse {
  transform: rotate(180deg);
  transform-origin: center 3.5px;
  transition: transform 0.4s;
}
.search-bar {
  display: flex;
  align-items: center;
}
.btn {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  box-sizing: border-box;
  :nth-child(1) {
    width: 165px;
    height: 44px;
    color: rgb(255, 255, 255);
    font-family: PingFang SC;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* 主色 */
    background: rgb(35, 112, 255);
    /* 蓝色阴影 */
    box-shadow: 0px 2px 10px rgba(35, 112, 255, 0.4);
    border-radius: 22px;
    border: none;
  }
  :nth-child(2) {
    width: 165px;
    height: 44px;
    color: rgb(35, 112, 255);
    font-family: PingFang SC;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* 主色 */
    border: 1px solid rgb(35, 112, 255);
    border-radius: 22px;
    background: none;
  }
}
.aisleStatus {
  margin-top: 10px;
  font-family: PingFang SC;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  .aisle {
    color: #0eb556;
  }
}
::v-deep .el-input__inner {
  width: 548rpx !important;
  height: 88rpx;
  /* 灰色背景 */
  background: rgb(248, 248, 248);
  /* 描边 */
  border: 1px solid rgb(229, 229, 229);
  border-radius: 5px;
}
</style>
